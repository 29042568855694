import "./section-break.scss";

import React from "react";

export interface ISectionBreakProps {
	isRandom?: boolean;
	type?: "type1" | "type1 mir-xy" | "type1 inv" | "type1 mir-x" | "type2" | "type2 mir-x";
	background?: string;
	translate?: number;
	height?: number;
	className?: string;
	style?: any | undefined;
	children?: any;
}

function getRandomClipPaths(): string {
	var lastX = 0,
		randX,
		randY,
		polygonPoints = ["0% 0%"];

	randY = Math.floor(Math.random() * 20) + 80;

	polygonPoints.push(lastX + "% " + randY + "%");

	while (lastX <= 100) {
		randX = Math.floor(Math.random() * 25);
		randY = Math.floor(Math.random() * 20) + 85;
		polygonPoints.push(randX + lastX + "% " + randY + "%");
		lastX = lastX + randX;
	}
	polygonPoints.push("100% 0%");

	return "polygon(" + polygonPoints.join() + ")";
}

export default function SectionBreak(props: ISectionBreakProps) {
	return (
		<div
			className={
				"section-break " +
				(props.type??"") +
				" " +
				(props.className !== undefined ? props.className : "")
			}
			style={{
				...props.style,
				marginBottom:
					props.translate !== undefined ? props.translate : undefined,
				height: props.height !== undefined ? props.height : undefined
			}}>
			{/* {"<"} Section Break {">"} */}
			<div
				className={"separator"}
				style={
					props.isRandom
						? {
								WebkitClipPath: getRandomClipPaths(),
								clipPath: getRandomClipPaths(),
								background: props.background
									? props.background
									: "none"
						  }
						: {
								background: props.background
									? props.background
									: "none"
						  }
				}>
				{props.children}
			</div>
		</div>
	);
}
