import './edition.scss';

import React from 'react';
import { Button } from 'react-bootstrap';
import HitAndShake from '../hit-and-shake/HitAndShake';

export interface IEditionProps {
	title: string;
	latinQuote: string;
	englishQuote: string;
	description: string;
	productImage: string;
	price?: number;
	onBuy: any;
	id?: string;
	buyText?: any;
	productCol?: string;
	available?: boolean;
}

export default function Edition(props: IEditionProps) {
	const available = props.available ?? true;

	return (
		<div className="edition col-12 col-lg-10 col-xl-8 offset-lg-1" id={props.id}>
			<h2 className="col-8 offset-2">{props.title}</h2>
			<div className="col-10 offset-2 row p-0">
				<div className="quote-box col-7">
					<div className="quote-latin">&laquo;{props.latinQuote}&raquo;</div>
					<div className="quote-english">{props.englishQuote}</div>
					<div className="description">{props.description}</div>
				</div>
				<div className="price col-2">
					{props.price && props.price > 0 && (
						<>
							<span
								style={{
									textDecoration: available ? 'none' : 'line-through',
								}}>
								{new Intl.NumberFormat('de-DE', {
									style: 'currency',
									currency: 'EUR',
								}).format(props.price)}
							</span>
							<span className="tax-label">incl. taxes</span>
						</>
					)}
				</div>
			</div>
			<div className={`product-box ${props.productCol || 'col-9'} offset-2`}>
				<div className="product-details" onClick={() => props.onBuy()}>
					<img src={props.productImage} alt={props.title}></img>
					{available && <Button onClick={() => props.onBuy()}>{props.buyText || 'Buy the bottle'}</Button>}
					<div className="price">
						{!available && (
							<h2 className="text-dark f-bold my-1" style={{ marginLeft: '-1rem' }}>
								SOLD OUT
							</h2>
						)}
					</div>
				</div>
				{/* <div className="price">
					{new Intl.NumberFormat("de-DE", {
						style: "currency",
						currency: "EUR"
					}).format(props.price)}
					<span className="tax-label">incl. taxes</span>
				</div> */}
			</div>
			{/* <HitAndShake></HitAndShake> */}
		</div>
	);
}
