import './parallax-image.scss';

import React from 'react';

export interface IParallaxImageProps {
	image: string;
	imageAlt: string;
	title: string | any;
	description?: string;
	background?: string;
	hasReflection?: boolean;
	reflectionImage?: string;
	id?: string;
	textClass?: any;
	imageBorder?: boolean;
	disableParallax?: boolean;
}

export default class ParallaxImage extends React.Component<IParallaxImageProps> {
	image: React.RefObject<HTMLImageElement>;
	reflection: React.RefObject<HTMLImageElement>;

	constructor(props: IParallaxImageProps) {
		super(props);

		this.image = React.createRef();
		this.reflection = React.createRef();
		if (props.hasReflection) {
			this.handleScroll = this.handleScroll.bind(this);
		}
	}

	componentDidMount() {
		if (this.props.hasReflection) {
			window.addEventListener('scroll', this.handleScroll);
			setTimeout(() => this.handleScroll(undefined), 700);
		}
	}

	componentWillUnmount() {
		if (this.props.hasReflection) window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event?: Event) {
		if (!this.image || !this.reflection) return;

		var transform = this.image.current!.style.transform;
		this.reflection.current!.style.transform = transform;
	}

	render() {
		const { props } = this;

		return (
			<div
				id={props.id}
				className={'image-section' + (!props.disableParallax ? ' parallax' : '')}
				style={{
					background: props.background ?? 'white'
				}}>
				<img
					src={props.image}
					alt={props.imageAlt}
					ref={this.image}
					// style={{
					// 	backgroundImage: "url(" + props.image + ")"
					// }}
				/>
				{props.hasReflection && (
					<div className="reflection-wrapper">
						<img src={props.reflectionImage} alt="reflection" className="reflection-img" ref={this.reflection} />
					</div>
				)}
				{/*{props.hasReflection && (
					<div
					className="reflection-container"
					style={{
						backgroundImage: "url(" + props.image + ")"
					}}></div>
				)} */}
				<div className="text-content " style={props.imageBorder ? { borderTop: '2px solid black' } : {}}>
					{props.hasReflection && <div className="reflection-fade" />}
					{
						<div className={'inner ' + (props.textClass !== undefined ? props.textClass : 'col-10 offset-1')}>
							{props.title && (typeof props.title === 'string' ? <h2>{props.title}</h2> : props.title)}
							{props.description && <p>{props.description}</p>}
							{props.children}
						</div>
					}
				</div>
			</div>
		);
	}
}
