import './recipe.scss';

import React from 'react';

export interface IRecipeProps {
	title1: string;
	title2?: string | undefined;
	ingredients: { name: string; amount: number; unit: string; href?: string }[];
	steps: string[];
	location?: string | undefined;
	authorInstitution?: string | undefined;
	authorName: string;
}

export default function Recipe(props: IRecipeProps) {
	return (
		<div className="recipe col-12" id={(props.title1 + ' ' + (props.title2 !== undefined ? props.title2 : '')).toLowerCase().trim().split(' ').join('-').replace('&shy;', '')}>
			<div className="recipe-inner">
				<div className="top">
					<h2 dangerouslySetInnerHTML={{ __html: props.title1 }}></h2>
					<h3>{props.title2}</h3>
					<div className="ingredients-list">
						{props.ingredients.map((i, n) => (
							<div className="ingredient" key={n}>
								{i.amount !== 0 ? i.amount : ''}
								{i.unit}{' '}
								{i.href ? (
									<a target="_blank" rel="noopener noreferrer" href={i.href}>
										{i.name}
									</a>
								) : (
									i.name
								)}
							</div>
						))}
					</div>
				</div>

				<div className="bottom">
					<div className="steps-list">
						{props.steps.map((s, i) => (
							<div className="step" key={i}>
								{s}
							</div>
						))}
					</div>
				</div>
				<div className="triangle"></div>

				<div className="authors">
					{/* {props.authorInstitution !== undefined && <span className="institution">{props.authorInstitution}</span>}
					{props.authorInstitution !== undefined && props.location !== undefined && ' / '}
					{props.location !== undefined && <span>{props.location}</span>} */}
					<div className="author">{props.authorName}</div>
				</div>
			</div>
		</div>
	);
}
