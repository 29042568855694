import "./recipes.scss";

import React from "react";
import ParallaxImage from "../parallax-image/ParallaxImage";

import recipesImg from "./../../assets/recipes-6.jpg";
import Recipe from "../recipe/Recipe";

export default function Recipes(props: { id?: string; data: any }) {
	return (
		<div className="recipes" id={props.id}>
			<ParallaxImage
				image={recipesImg}
				imageAlt="Recipes"
				title=""
				description=""></ParallaxImage>

			{/* <h2 className="title">Recipes</h2> */}

			<div className="recipe-list">
				{props.data.recipes.map((r: any, i: number) => (
					<Recipe
						title1={r.title1}
						title2={r.title2}
						ingredients={r.ingredients}
						steps={r.steps}
						location={r.location}
						authorInstitution={r.authorInstitution}
						authorName={r.authorName}
						key={i}
					/>
				))}
			</div>

			{/* <Recipe
				title1="Heckendalheim 1342"
				title2="Well served"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 3, unit: "cl" },
					{ name: "ice cubes", amount: 5, unit: "" }
				]}
				steps={["Stir and let for three minutes ‒ use a tumbler."]}
				location="Berlin"
				authorName="John Pelikan"
			/>

			<Recipe
				title1="The Green Heck"
				ingredients={[
					{ name: "bulleit rye whiskey", amount: 5, unit: "cl" },
					{ name: "HECKENDALHEIM 1342", amount: 2, unit: "cl" },
					{ name: "maple syrup", amount: 1, unit: "cl" },
					{
						name: "freshly pressed lime juice",
						amount: 2,
						unit: "cl"
					},
					{ name: "pear juice", amount: 4, unit: "cl" },
					{ name: "Handful fresh basil", amount: 0, unit: "" }
				]}
				steps={[
					"Shake, double strain, served on the rocks in tumbler glass",
					"Fill up 1 splash soda water",
					"Garnish with basil"
				]}
				authorName="Inspector Dev & Kevin Domanski"
			/>

			<Recipe
				title1="Fizz 136"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 4, unit: "cl" },
					{
						name: "freshly pressed lime juice",
						amount: 3,
						unit: "cl"
					},
					{ name: "sugar syrup", amount: 2, unit: "cl" },
					{ name: "soda", amount: 2, unit: "cl" },
					{ name: "Ice cubes", amount: 0, unit: "" }
				]}
				steps={[
					"Pour all the condiments, besides the soda, in an ice filled shaker and shake.",
					"Then cant everything in a long glass and fill with the soda."
				]}
				location="Darmstadt"
				authorInstitution="Cafe Hess"
				authorName="Herr Ben"
			/>

			<Recipe
				title1="Mexican Lady"
				ingredients={[
					{ name: "el jimador blanco", amount: 4, unit: "cl" },
					{ name: "dan cozme mezcal", amount: 1, unit: "cl" },
					{ name: "HECKENDALHEIM 1342", amount: 2, unit: "cl" },
					{ name: "noilly prat dry", amount: 2, unit: "cl" },
					{
						name: "freshly pressed lemons",
						amount: 0,
						unit: "½ - 1"
					},
					{ name: "feigenmarmelade", amount: 1, unit: "cash spoon" },
					{ name: "sea salt", amount: 2, unit: "pinch" },
					{
						name: "freshly pressed lime juice",
						amount: 2,
						unit: "cl"
					},
					{ name: "pear juice", amount: 4, unit: "cl" },
					{ name: "Handful fresh basil", amount: 0, unit: "" }
				]}
				steps={[
					"Shake, double strain, served straight up (without ice) in coupé/martini glass."
				]}
				authorName="Inspector Dev & Kevin Domanski"
			/>

			<Recipe
				title1="A Stranger came to Berlin"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 3, unit: "cl" },
					{ name: "gin tenqueray", amount: 4, unit: "cl" },
					{
						name: "freshly pressed lime juice",
						amount: 2,
						unit: "cl"
					},
					{
						name:
							"homemade green tea (i. e. Darboven, Die Sonne Asiens,...)",
						amount: 8,
						unit: "cl"
					},
					{ name: "Ice cubes", amount: 0, unit: "" }
				]}
				steps={[
					"First of all, brew up the green tea.",
					"Then, fill the shaker with ice and add all other liquids, pour the green tea last.",
					"Pick a long glass and fill it also up with ice.",
					"Shake well and use a strainer to separate the 'warm' ice from the new ice."
				]}
				authorInstitution="La Banca ‒ Hotel de Rome"
				location="Berlin"
				authorName="Daniel Klingseis & Dennis Dallar"
			/>

			<Recipe
				title1="The rising fruits"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 4, unit: "cl" },
					{ name: "southern comfort OR", amount: 1, unit: "cl" },
					{ name: "vecchia romagna", amount: 1, unit: "cl" },
					{
						name: "lime juice",
						amount: 2,
						unit: " ½ cl"
					},
					{ name: "maple syrup", amount: 1, unit: "cl" },
					{ name: "Handful mint", amount: 0, unit: "" },
					{ name: "Fresh strawberries", amount: 0, unit: "" }
				]}
				steps={[
					"First muddle the strawberries, single strain.",
					"Fill up with mate OR ginger ale (sweeter)."
				]}
				authorName="Inspector Dev & Kevin Domanski"
			/>

			<Recipe
				title1="Bongartz Royale"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 2, unit: "cl" },
					{
						name:
							"bittersweet rosé-champagne (i. e. Champagne Billecart-Salmon Brut Rosé)",
						amount: 4,
						unit: "cl"
					}
				]}
				steps={[
					"Pick a champagne glass.",
					"First pour the brut rosé then the HECKENDALHEIM 1342."
				]}
				location="Darmstadt"
				authorInstitution="Cafe Hess"
				authorName="Herr Ben"
			/>

			<Recipe
				title1="Bongartz Transporter"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 3, unit: "cl" },
					{
						name:
							"reddish dark beer (i. e. Weschnitztaler Braumanufaktur Basalt)",
						amount: 5,
						unit: "cl"
					},
					{ name: "Ice cubes", amount: 0, unit: "" }
				]}
				steps={[
					"Pour the HECKENDALHEIM 1342 on ice, then add some dark beer.",
					"Put the bottle beside and pour at your whim."
				]}
				location="Darmstadt"
				authorInstitution="Cafe Hess"
				authorName="Herr Ben & John Pelikan"
			/>

			<Recipe
				title1="Heckendalheim 1342"
				title2="Cut"
				ingredients={[
					{ name: "HECKENDALHEIM 1342", amount: 3, unit: "cl" },
					{
						name: "soda (i. e. Carola Rouge)",
						amount: 3,
						unit: " cl"
					},
					{ name: "ice cubes", amount: 5, unit: "" }
				]}
				steps={[
					"First pour HECKENDALHEIM 1342 on the rocks, then the soda ‒",
					"slew the tumbler."
				]}
				location="Berlin"
				authorName="John Pelikan"
			/> */}
		</div>
	);
}
