import React, { PropsWithChildren, ReactNode } from 'react';
import { Modal as BsModal, ModalProps as BsModalProps } from 'react-bootstrap';

export type ModalProps = BsModalProps & PropsWithChildren<{
	title?: ReactNode;
}>;

export const Modal: React.FC<ModalProps> = ({ title, children, ...modalProps }) => {
	return (
		<BsModal {...modalProps}>
			<BsModal.Header closeButton>{title && <BsModal.Title>{title}</BsModal.Title>}</BsModal.Header>
			<BsModal.Body>{children}</BsModal.Body>
		</BsModal>
	);
};

export default Modal;
