import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/app/App";
import * as serviceWorker from "./serviceWorker";
import simpleParallax from "simple-parallax-js";
import Data from "./assets/data.json";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
	<React.StrictMode>
		<Router basename={"/Content/start-page"}>
			<App data={Data} />
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

var images = document.querySelectorAll(".parallax > img");
//@ts-ignore
new simpleParallax(images, {
	delay: 0,
	transition: "cubic-bezier(0,0,0,1)",
	scale: 1.5
});

var vectors = document.querySelectorAll(".parallax > svg");
console.log(vectors);
//@ts-ignore
new simpleParallax(vectors, {
	delay: 0.6,
	transition: "cubic-bezier(0,0,0,1)"
});

// var reflections = document.querySelectorAll(
// 	".parallax > .reflection-wrapper > img"
// );
// //@ts-ignore
// new simpleParallax(reflections, {
// 	delay: 0.6,
// 	transition: "cubic-bezier(0,0,0,1)",
// 	orientation: "down"
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
