import './intro.scss';

import React from 'react';
import SectionBreak from '../section-break/SectionBreak';
import headerLogo from '../../assets/heckendalheim-header-2.svg';
import introImage from '../../assets/intro-original.jpg';
import loveIntro from '../../assets/intro-love-block.svg';
import theRareOne from '../../assets/the-rare-one.jpg';
import { ReactComponent as SmallEditionOverview } from '../../assets/small-edition-overview.svg';
import ParallaxImage from '../parallax-image/ParallaxImage';
import Block from '../block/Block';
import { Button } from 'react-bootstrap';
import Modal from '../modal/Modal';

export default class Intro extends React.Component<{ id?: string; data: any }, { storyModalVisible: boolean }> {
	/**
	 *
	 */
	constructor(props: any) {
		super(props);
		this.state = { storyModalVisible: false };
	}

	render() {
		return (
			<header className="start-page-header col-12" id={this.props.id}>
				<div className="intro">
					{/* <Sitemap /> */}
					<div className="header-images">
						<img src={headerLogo} alt="Heckendalheim 1342 Header" className="header-logo" />
						{/* <img
							src={loveIntro}
							alt="Make Love True"
							className="natural-badge "
						/> */}
					</div>
					<SectionBreak type="type1 mir-x" background="white" style={{ position: 'relative', zIndex: 100 }} />
					<div className="intro-text-part ">
						{/* <img src={introImage} className="d-none d-lg-block" alt="John Pelikan" />					 */}
						{/* <span
							dangerouslySetInnerHTML={{
								__html: require(introImageText)
							}}
						/> */}
						{/* <LogoXHigh className="d-block d-md-none" /> */}
						{/* <LogoHigh className="d-none d-md-block d-lg-none" /> */}
						{/* <Logo className="d-none d-lg-block" /> */}
						<ParallaxImage imageAlt="Heckendalheim 1342" image={introImage} title="" background="linear-gradient(rgb(185, 185, 185) 80%, rgb(206, 206, 206))" description="" disableParallax={true} imageBorder={true}>
							<div
								style={{
									textAlign: 'center',
									fontSize: '1rem',
									position: 'relative',
									left: '-.4rem',
								}}>
								&laquo;&nbsp;
								<span className="font-weight-bold">
									John Pelikan und die beiden Erdenastronauten,
									<br /> Timmy Zaubermann und Plam der schöne Bär
								</span>
								&nbsp;&raquo;
								<br />
								<br /> &mdash;
								<br />
								<br /> &laquo;&nbsp;John Pelikan and the two Earthonauts,
								<br /> Timmy the Magician and Plam the handsome Bear&nbsp;&raquo;
								<br />
								<Button
									className="btn__story hkdm-button px-4"
									variant="dark"
									title={this.props.data.story.buttonText}
									onClick={() => {
										this.setState((s) => ({ ...s, storyModalVisible: true }));
									}}>
									{this.props.data.story.buttonText}
								</Button>
								<Modal
									title={
										<div className="modal__story title-wrapper">
											<div className="title">{this.props.data.story.title}</div>
											<div className="subtitle">{this.props.data.story.subtitle}</div>
										</div>
									}
									show={this.state.storyModalVisible}
									onHide={() => this.setState((s) => ({ ...s, storyModalVisible: false }))}>
									{(this.props.data.story.content as string[]).map((s) => (
										<p>{s}</p>
									))}
								</Modal>
							</div>
						</ParallaxImage>
						{/* <SectionBreak
							type="type1"
							// translate={-140}
							background="linear-gradient(rgb(215,141,0), rgb(238, 156, 0))"
						/> */}
						<Block height={80} background="linear-gradient(rgb(206,206,206), rgb(215, 215, 215))"></Block>
						{/* <LogoBorder className="diagonal-block" /> */}
					</div>
					{/* <div className="small-edition-overview col-md-7 offset-md-4 col-10 offset-2"> */}
					<div className="the-rare-one">
						{/* <img
							src={theRareOne}
							alt="The Rare One - First Edition"
						/> */}
						{/* <SmallEditionOverview /> */}
						{/* <img
						src={specialtyBubble}
						alt="Exclusive Specialty for dance, dialogue and charity."
					/>
					<Row>
						<img
							src={edition3}
							className="col-4"
							alt="The third edition."
						/>
						<img
							src={edition2}
							className="col-4"
							alt="The second edition."
						/>
						<img
							src={edition1}
							className="col-4"
							alt="The first edition."
						/>
					</Row> */}
					</div>
				</div>
			</header>
		);
	}
}
