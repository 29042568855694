import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './sitemap.scss';
import '../scroll-spy/scroll-spy.scss';

export default function Sitemap(props: { data: any }) {
	return (
		<div className="page-sitemap col-12">
			<div className="nav">
				<ul className="col-12">
					{props.data.sitemap.items.map((item: any, i: any) => {
						if (item.id !== 'recipes') {
							return (
								<li className={item.size} key={i}>
									{item.href ? <a href={item.href}>{item.name}</a> : <a href={'#' + item.id}>{item.name}</a>}
								</li>
							);
						} else {
							return (
								<li className={item.size} key={i}>
									<Dropdown>
										<Dropdown.Toggle variant="success" id="dropdown-basic">
											<a href={'#' + item.id}>{item.name}</a>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{props.data.recipes.map((r: any, i: number) => (
												<Dropdown.Item key={i} href={'#' + (r.title1 + ' ' + (r.title2 !== undefined ? r.title2 : '')).toLowerCase().trim().split(' ').join('-').replace('&shy;', '')}>
													<span
														dangerouslySetInnerHTML={{
															__html: r.title1,
														}}></span>
													<span>{' ' + (r.title2 !== undefined ? r.title2 : '')}</span>
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</li>
							);
						}
					})}

					{/* <Row className="uppercase">
				<Col xs={3}>
					<span>Contact</span>
				</Col>
				<Col xs={3}>
					<span>Imprint</span>
				</Col>
				<Col xs={3}>
					<span>Terms of Condition</span>
				</Col>
				<Col xs={3}>
					<span>Login</span>
				</Col>
			</Row>
			<Row className="uppercase">
				<Col xs={4}>
					<span>
						<a href="#third-edition">III. Edition</a>
					</span>
				</Col>
				<Col xs={4}>
					<span>
						<a href="#second-edition">II. Edition</a>
					</span>
				</Col>
				<Col xs={4}>
					<span>
						<a href="#first-edition">I. Edition</a>
					</span>
				</Col>
			</Row>
			<Row className="lowercase">
				<Col xs={4}>
					<span>
						<a href="#philosophy">Philosophy</a>
					</span>
				</Col>
				<Col xs={4}>
					<span>
						<a href="#interview">Interview</a>
					</span>
				</Col>
				<Col xs={4}>
					<span>
						<a href="#recipes">Recipes</a>
					</span>
				</Col>
			</Row>*/}
				</ul>
			</div>
			<footer>
				<div>
					Ausschließliche Specialität für
					<br />
					Tanz, Dialog und Nächstenliebe.
				</div>
				<div className="mt-3">Elixir légal&nbsp;-&nbsp; 17% Alcohol &nbsp;/&nbsp; 34% Percolate</div>
				<div className="my-3 mt-4">
					<a href="/login">Login</a>
				</div>
			</footer>
		</div>
	);
}
