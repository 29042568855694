import './app.scss';

import React from 'react';

import Intro from '../intro/Intro';
import { Button, Container, Row } from 'react-bootstrap';
import SectionBreak from '../section-break/SectionBreak';
import EditionPreview from '../edition-preview/EditionPreview';
import Edition from '../edition/Edition';
import ParallaxImage from '../parallax-image/ParallaxImage';
import HitAndShake from '../hit-and-shake/HitAndShake';

import edition1Rot from '../../assets/edition-1-rot.svg';
import edition2Rot from '../../assets/edition-2-rot.svg';
import edition3Rot from '../../assets/edition-3-rot.svg';
import editionJw1Rot from '../../assets/edition-jw-1-rot-wax-2.svg';
import editionJw1Plam from '../../assets/edition-jw-1.jpg';
import hkdmLives from '../../assets/hkdm1342-lives-high.png';
import cloudyNature from '../../assets/cloudy-nature-2.jpg';
import cloudyNatureReflection from '../../assets/cloudy-nature-2.jpg';
import edition1Background from '../../assets/first-edition-background.jpg';
import alpinblauBackground from '../../assets/Titelbild-NEU-alpinblau-Webseite-scaled.jpg';
import Interview from '../interview/Interview';
import Recipes from '../recipes/Recipes';
import Block from '../block/Block';
import Sitemap from '../sitemap/Sitemap';
import ScrollSpy from '../scroll-spy/ScrollSpy';
import { transform } from 'typescript';
// import ScrollToTop from "../scroll-to-top/ScrollToTop";

export interface IAppProps {
	data: any;
}

function App(props: IAppProps) {
	console.log(props.data);
	return (
		<div className="app">
			<Container>
				<Row>
					{/* <ScrollToTop></ScrollToTop> */}
					<header className="page-header col-12">
						<div className="col-8 my-2">
							<div className="col-12 col-md-3">Einfach </div>
							<div className="col-12 col-md-3">Konsequent</div>
							<div className="col-12 col-md-3">Natur</div>
						</div>						
						{/* <div className="col-8 d-none d-md-block">
							<div className="d-flex justify-content-around">
								<span>Einfach</span>
								<span>&nbsp;-&nbsp;</span>
								<span>Konsequent</span>
								<span>&nbsp;-&nbsp;</span>
								<span>Natur</span>
							</div>
						</div> */}
					</header>
					{/* <ScrollSpy data={props.data} /> */}
					<Intro id="intro" data={props.data}></Intro>

					{/* <EditionPreview /> */}

					{/* <Block height={100} background="black"></Block> */}
					{/* <SectionBreak
						type="type1"
						// translate={-140}
						background="black"></SectionBreak> */}						
					 <SectionBreak 
						type="type1"
						// translate={-140}
						background="rgb(215,215,215)"></SectionBreak>
					<Edition
						id="first-jw-edition"
						title="The First John Pelikan Edition"
						latinQuote="Est-ce qu'on est libre dans la présence de l'autrui?"
						englishQuote="Is one free in the presence of another?"
						description=""
						productImage={editionJw1Rot}
						price={64.99}
						onBuy={() => (window.location.href = '/erste-john-pelikan-edition')}
					/>
					<SectionBreak type="type1 inv" background="white" />
					<ParallaxImage
						title={
							<h2
								style={{
									color: 'black',
									textAlign: 'center',
									textTransform: 'initial',
									fontSize: '1.5rem',
									position: 'relative',
									top: '-.75rem',
								}}>
								<span className="uppercase">What the heck?</span>
							</h2>
							// "Heckendalheim 1342 lives"
						}
						imageAlt="What the heck"
						image={editionJw1Plam}
						description=""
						hasReflection={false}
						imageBorder={true}
						background="linear-gradient(rgb(247, 110, 61) 60%, rgb(255, 165, 111))"
					/>
					<SectionBreak
						type="type1"
						// translate={-140}
						background="rgb(255, 165, 111)"></SectionBreak>
					<Edition
						id="third-edition"
						title="The Third Hubertus Rohde Edition"
						latinQuote="Pulchrae sunt quae visa placent"
						englishQuote="Beautiful is what pleases your sight"
						description=""
						productImage={edition3Rot}
						price={134.9}
						onBuy={() => (window.location.href = '/dritte-hubertus-rohde-edition')}
					/>
					<SectionBreak type="type1 inv" background="white" />
					<ParallaxImage
						title={
							<h2
								style={{
									color: 'white',
									textAlign: 'left',
									textTransform: 'initial',
								}}>
								<span className="uppercase">Heckendalheim 1342</span> lives...
							</h2>
							// "Heckendalheim 1342 lives"
						}
						imageAlt="Heckendalheim 1342 lives"
						image={hkdmLives}
						description=""
						hasReflection={false}
						imageBorder={true}
						background="linear-gradient(rgb(78, 73, 71) 60%, rgb(157, 151, 151))">
						<p className="my-5" style={{ textAlign: 'start', fontSize: '1rem' }}>
							The taste of <span className="uppercase">Heckendalheim 1342</span> <br />
							underlies to an endless ripening.
							<br /> In order to influence the evolution of flavour
							<br /> by nothing else than the fine condiments themselves
							<br /> we use <em className="uppercase">Flaschenlagerung.</em>
						</p>
					</ParallaxImage>
					<SectionBreak
						type="type1"
						// translate={-140}
						background="linear-gradient(rgb(157, 151, 151), rgb(203, 202, 196))"
					/>
					<Edition
						id="second-edition"
						title="The Second Hubertus Rohde Edition"
						latinQuote="Quid quid agis prudenter agas et respice finem"
						englishQuote="However you act, act carefully and respect the end"
						description=""
						productImage={edition2Rot}
						price={67.9}
						onBuy={() => (window.location.href = '/zweite-hubertus-rohde-edition')}
					/>
					<SectionBreak type="type1 inv" background="white" />
					<ParallaxImage id="philosophy" imageAlt="The cloudy nature" image={cloudyNature} title="" description="" hasReflection={true} reflectionImage={cloudyNatureReflection}>
						<h2 className="uppercase primary mb-5">
							<span className="text-white mb-2 d-inline-block">Unfiltered</span>
							<br />
							<span className="text-body">The cloudy nature</span>
						</h2>
						<p className=" mt-5" style={{ fontSize: '1rem', textAlign: 'left' }}>
							We handle a purely natural product, <br />
							elaborated in an unique <br />
							manufacturing process. 					
							<br /> We refuse to filter for the
							<br />
							perfect taste and mouthfeel.
						</p>
						{/* <HitAndShake important={true}></HitAndShake> */}
					</ParallaxImage>
					<Block height={90} background="linear-gradient(to bottom, #ffd88b, #f2c261)" />
					<SectionBreak
						type="type1"
						// translate={-140}
						background="#f2c261"
					/>
					<Edition
						id="first-edition"
						title="The First Hubertus Rohde Edition"
						latinQuote="Geburt ist Sterbens Anfang. Tod ist Lebens Aufgang, strahlender Beginn"
						englishQuote="Birth is initial of dying. Death is the rising of living. Bright beginning."
						description=""
						productImage={edition1Rot}						
						available={false}
						onBuy={() => (window.location.href = '/erste-hubertus-rohde-edition')}
						buyText={
							<span style={{ whiteSpace: 'normal' }}>
								Are you sick
								<span className="position-relative" style={{ top: '-1px' }}>
									?
								</span>
							</span>
						}
					/>
					<SectionBreak type="type1 inv" background="white" />
					<ParallaxImage imageAlt="First Hubertus Rohde Edition" image={edition1Background} title="" hasReflection={false} background="linear-gradient(to bottom, #f3fff0 80%, #a6cc9e)" imageBorder={true} description="">
						<div style={{ textAlign: 'start', fontSize: '1rem' }}>
							<span className="uppercase">The First Hubertus Rohde Edition</span>
							<br />
							produced in summer 2014.
							<br />
							<em>247 bottles</em> were made
							<br />
							and prices rose.
							{/* <br /> */}
							<br /> None is left.
						</div>
					</ParallaxImage>
					<Block height={70} background="linear-gradient(#a6cc9e, #97bf8e)" />
					<SectionBreak
						type="type2"
						// translate={-160}
						background="linear-gradient(#97bf8e, #7db970)"
					/>
					<Interview id="interview" data={props.data} />
					<SectionBreak type="type2 mir-x" translate={-300} background="white" />
					<Recipes id="recipes" data={props.data} />
					<SectionBreak type="type1 mir-x" background="#000000" style={{ marginTop: '-3rem' }} />
					<ParallaxImage imageAlt="Alpinblau Wandertouren - Felsiges Bergpanorama" image={alpinblauBackground} title="" hasReflection={false} background="linear-gradient(to bottom, #306c95 70%, #000 90%, #000)" imageBorder={true} description="">
						<a href="https://alpinblau.com/" style={{ position: 'relative', top: '-10rem' }}>
							<Button className="hkdm-button px-4">Descansar</Button>
						</a>
					</ParallaxImage>					
					<Sitemap data={props.data} />
					{/* <a className="attribution" href="https://icons8.com">
						Icons by Icons8
					</a> */}
				</Row>
			</Container>
		</div>
	);
}

export default App;
