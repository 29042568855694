import './block.scss';

import React from "react";

export interface IBlockProps {
	height: number;
	background?: string;
}

export default function Block(props: IBlockProps) {
	return (
		<div
			className="block"
			style={{
				height: props.height,
				background: props.background ? props.background : "none"
			}}></div>
	);
}
