import "./interview.scss";

import React from "react";

export default function Interview(props: { id?: string; data: any }) {
	return (
		<div className="interview col-11 offset-1" id={props.id}>
			<h2 className="mt-4 uppercase">Tanz, Dialog und Nächstenliebe.</h2>

			{props.data.interview.map((e: any, i: number) => (
				<div className="interview-item" key={i}>
					<div className="question">
						<span className="prefix">
							{e.questionPrefix !== undefined
								? e.questionPrefix + " "
								: ""}
						</span>
						{e.question}
					</div>
					<div className="answer">
						<span className="prefix">
							{e.answerPrefix !== undefined
								? e.answerPrefix + " "
								: ""}
						</span>
						{e.answer}
					</div>
				</div>
			))}
		</div>
	);
}
